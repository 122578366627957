import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './blog-post.module.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const ProductTemplate = (props) => {
  const product = get(props, 'data.contentfulProduct')

  return (
    <Layout location={props.location}>
      <Seo
        title={`${product.name} | Ronak Electronics`}
        image={`http:${product.featureImage.resize.src}`}
      />

      <Hero
        image={product.featureImage.gatsbyImageData}
        title={product.name}
        content={product.description}
      />
      <div className={styles.container}>
        <div className={styles.article}>
          {product.body && (
            <div className={styles.body}>{renderRichText(product.body)}</div>
          )}

          {product.pdf && (
            <Download>
              <a href={`http:${product.pdf.file.url}`} target="_blank">
                Download PDF
              </a>
            </Download>
          )}
          <Images>
            {product.images.map((i) => (
              <div key={i.id}>
                <GatsbyImage alt={i.title} image={i.gatsbyImageData} />
                <div>
                  <h3>{i.title}</h3>
                  <p>{i.description}</p>
                </div>
              </div>
            ))}
          </Images>
        </div>
      </div>
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductByName($name: String!) {
    contentfulProduct(name: { eq: $name }) {
      id
      name
      description
      pdf {
        file {
          url
        }
      }
      body {
        raw
      }
      featureImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize {
          src
        }
      }
      images {
        id
        description
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize {
          src
        }
      }
    }
  }
`

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  h3 {
    margin-top: 0;
  }
  p {
    font-size: 18px;
    color: rgb(0 0 0 / 70%);
    margin: 0;
    line-height: 22px;
    white-space: pre-wrap;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
  }
`

const Download = styled.div`
  text-align: center;
  padding: 0 0 20px 0;
  a {
    background: ${({ theme }) => theme.color.p};
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 22px;
    font-weight: bold;
  }
`
